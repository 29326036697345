<script setup>
import { ref } from 'vue';
import { RouterLink } from 'vue-router';
const brands = ref(null);
fetch('https://t4a.su/api/nb')
    .then(response => response.json())
    .then(data => brands.value = data.brands.items);
</script>

<template>
<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <router-link class="navbar-brand" :to="{name: 'home'}">T4A.su<br><span class="fs-6">Техника для всех</span></router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'about'}">About</router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Brands
          </a>
          <ul class="dropdown-menu">
            <li v-for="brand in brands" :key="brand.id"><router-link class="dropdown-item" :to="{name: 'notebook', params:{ brand: brand.slug} }">{{brand.title}}</router-link></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" aria-disabled="true">Disabled</a>
        </li>
      </ul>

      <ul class="navbar-nav justify-content-end">
        <li class="nav-item">
          <router-link class="nav-link" to="#">Registration</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="{name: 'login'}">Login</router-link>
        </li>
      </ul>
      
      <form class="d-flex" role="search">
        <input class="form-control me-2" type="search" placeholder="Поиск по модели" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>
      
    </div>
  </div>
</nav>
</template>

<style>

</style>